<template>
  <van-share-sheet
    :value="value"
    title="立即分享给好友"
    :options="shareOption"
    @select="onSelect"
    @cancel="close"
    @click-overlay="close"
  />
</template>

<script>
import * as tools from '@/utils/src/tools';
var appShareMsg = (ex, appInfo = {}) => {
    let hash = window.location.href.split(process.env.NODE_ENV === 'development' ? '8081/' : '.cn/');
  const url = `https://functional.lustudy.cn/${hash[1]}`;
  return {
    href: tools.handleSearchCodeUrl(url),
    title: appInfo.name,
    // content: '应知学问难,在乎点滴勤',
    content: '课本同步，提升孩子听说读写能力',
    thumbs: [`${appInfo.icon || appInfo.cover}?x-oss-process=image/resize,m_pad,w_200,m_lfit,color_b4ddff`],
    pictures: [`${appInfo.icon || appInfo.cover}?x-oss-process=image/resize,m_pad,w_200,m_lfit,color_b4ddff`],
    extra: {
      scene: ex
    }
  };
};
import * as auth from '@/utils/auth';
export default {
  name: 'app-share',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    shares: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      shareOption: [
        { name: '微信', icon: 'wechat', value: 'WXSceneSession' },
        { name: '朋友圈', icon: 'https://img01.yzcdn.cn/vant/share-sheet-wechat-moments.png', value: 'WXSceneTimeline' }
      ]
    };
  },
  computed: {},
  watch: {},
  methods: {
    onSelect(option) {
      this.appShare(option);
      this.$emit('input', false);
    },
    close() {
      this.$emit('input', false);
    },
    send(s, msg) {
      s.send(
        msg,
        function() {
          console.log('分享成功!');
        },
        function(e) {
          console.log('分享失败!');
        }
      );
    },
    appShare(opt) {
      let appInfo = auth.loc.get('appInfo', {});
      let vm = this;
      var s = null;
      s = vm.shares.weixin;
      const ex = opt.value;
      const _msg = appShareMsg(ex, appInfo);
      console.log(vm.shares, s);
      if (!s) {
        console.log('无效的分享服务！');
        return;
      }
      if (s.authenticated) {
        this.send(s, _msg);
      } else {
        s.authorize(
          () => {
            this.send(s, _msg);
          },
          () => {
            console.log('认证授权失败');
          }
        );
      }
    }
  }
};
</script>
